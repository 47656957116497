#nav_panel{
    box-sizing: border-box;
    padding: 10px;
    padding-left: 40px;
    width: 300px;
    height: 100%;
    background-color: inherit;
    user-select: none;
   
    z-index: 1000;
    overflow-y: auto;
    
}
.nav_panel_link{
    box-sizing: border-box;
    padding: 10px 20px;
    text-transform: capitalize;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
    margin: 10px 0;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.clear_decor{
    text-decoration: none;
    color: inherit
}
.nav_panel_link:hover{
    color:rgb(0, 0, 0)
}
.nav_panel_link img{
    height: 18px;
    width: 18px;
    margin-right: 10px;
}
#sys_name_navpanel{
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 20px;
}
#sys_name_navpanel > div:nth-child(1){
    font-weight: 700;
    font-size: 2em;
    color: rgb(0, 111, 196);
}
#sys_name_navpanel > div:nth-child(2){
    text-transform: uppercase;
    font-size: .7em;
    width: 60%;
    font-weight: 600;
    color: black;
}
.cur_location{  
    background-color: rgba(111, 155, 251, 0.206);
    border-radius: 5px;

}   
.nav_menu_btn{
    display: none;
}
.untouchable{
    pointer-events: none;
}
@media screen and (max-width:900px){
    #nav_panel{
        position: absolute;
        box-shadow: 0 3px 4px #848484;
        padding-left: 20px;
    }
}
@media screen and (max-width:600px){
    #nav_panel{
        width: 100%;
    
    }
}