#dash_main{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0 10%;
}
.dashButtons{
    padding: 10px 20px 20px;
    display: flex;
   
    justify-content: center;
}