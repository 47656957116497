.container,.container_placeholder{
    padding: 20px;
    margin: 10px;
    width: 300px;
 
}
.containerPiechart{
    padding: 20px;
    margin: 10px;

    width: 300px;    
    
}
.container_fixed{
    padding: 20px;
    margin: 10px;
    width: 300px;
    
}
.paper{
    box-shadow: 0 0 10px rgba(204, 204, 204, 0.356);
    
}
.container_main{
    padding: 10px;
}
.container_heading{
    font-size: 1.2em;
    padding: 5px 0;
    text-transform: capitalize;
    border-bottom: 1px solid #ccc;
}

.sale_object{
  
    padding: 10px;
   
  
}
.user_list_item_image{
    height: 30px;
    width: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.user_list_item_image >img{
    height: 35px;
    width: 35px;
}
.user_list_item{
    display: flex;
    flex-direction: row;
    padding:10px;
    align-items: center;
}
.user_list_item > div:nth-child(1){
    margin-right: 10px;
}
.person_list_item{
    display:inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
    margin: 10px  ;
    padding: 20px;

    cursor:pointer;
    max-width: 300px;
    
    
}
.person_list_item div{
    pointer-events: none;
    
}
.authenticator{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    margin: 10px 0 ;
    padding: 10px;

    cursor:pointer;
    width: 250px;
    border: 1px solid rgb(141, 141, 141);
    border-radius: 3px
    
    
}
.shop_balance{
    display: inline-block;
    
    border-radius: 50px;
    padding: 10px 20px;
    width: 250px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

}
.authenticator div{
    pointer-events: none;
}
.authenticator_final{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    margin: 10px 0 ;

    width: 250px;
    border-bottom: 1px solid rgb(109, 109, 109);

    
    
}
.authenticator_final div{
    pointer-events: none;
}
.authenticatorTitle{
    font-size: .8em;
}
.person_head{
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

}
 
.AccountName{
    font-size: 1.5em;

    color: black;
    padding:  0;

    
}
.accounthead_details{
 
   
}
.account_type{
    padding: 5px 0 20px;
    color: rgb(0, 155, 238);
    
}
.account_info_main{
    padding: 5% 20px;
}







.user_container{
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

}


.client_list_item{
    
}









.no_margin{
    margin: 0;
}

@media screen and (max-width:900px){
    .container, .container_fixed{
        padding: 20px 0;
    }
}
@media screen and (max-width:600px){
    .container, .container_fixed, .containerPiechart,.container_placeholder{
        width: 100%;
    }
}