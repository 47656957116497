.storeElm{
 
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

   
    margin: 10px;
    cursor: pointer;

}


#shopsMain{
   
    display: flex;
    flex-flow: row wrap;
    padding: 0 5%;
}
.storeElmName{
    font-size: 1.3em;
    padding: 5px 0;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
}
.storeElm img{
    height: 100%;
}
.storeElm div, .storeElm img{
    pointer-events: none;    
}
.storeElmImgContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    margin: 10px 0;
    overflow: hidden;

}
.storeElmMain{
    display: flex;
    flex-direction: column;
    align-items: center;
  
    justify-content: center;
  
}
@media screen and (max-width:600px){
    #shopsMain{
        padding: 0 
    }
}