#title_bar_users{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
 
}
.list_item_avatar{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;

}
.list_item_avatar img{
    height: 50px;
    width: 50px;
}
.userlist_item_avatar{
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
}
.userlist_item_avatar img{
    height: 100%;
    width: 100%;

}