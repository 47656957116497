.page{
    width: 100%;
    height: 100%;
    overflow-y: auto;

}
.system_title{
    font-size: 1.5em;
    font-weight: 300;
    padding: 20px;
   
    margin-bottom: 50px;
    text-align: center;
   
    width: 100%;
    box-sizing: border-box;

}
.displayText1{
    font-size: 1.5em;
    font-weight: 300;
}
.system_title > div{
    display: inline-block;
    padding: 10px 20px;
    border-bottom: 2px solid #ccc;
}
.info_value_pair{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 5px;
}
.info_value_pair > div:nth-child(1){
    
    font-weight: 300;
}
.info_value_pair > div:nth-child(2){
    padding-left: 10px;
    font-weight: 500;
    color: black;
}

.accordionItem{
    margin: 20px
}

.title_value_pair{
    padding: 10px;
}
.title_value_pair > div:nth-child(1){
    color: rgb(137, 137, 137);
    padding-bottom: 5px;
}
.title_value_pair > div:nth-child(2){
    font-weight: 600;
}
.info_value_pair_small{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 0 5px;
}
.info_value_pair_small > div:nth-child(2){
    padding-left: 5px;
    font-weight: 500;
    color: black;
}
.info_value_pair_inline{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding-right: 10px;
    
}
.info_value_pair_inline > div:nth-child(1){
    padding-left: 5px;
    font-weight: 500;
    color: black;
}
.padr5{
    padding-right: 5px;
}
.bold500{
    font-weight: 500;
}
.bold600{
    font-weight: 600;
}
.inlineFlexSpaceBtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex_row{
    display: flex;
    flex-direction: row;
}
.flex_column{
    display: flex;
    flex-direction: column;
}
.flex_column_center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.flex_row_center{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
}
.flex_align_center{
    align-items: center;
}
.flex_justify_center{
    justify-content: center;
}
.flex_justify_between{
    justify-content: space-between;
}
.flex_justify_end{
    justify-content: flex-end;
}
.flex_wrap{
    flex-wrap: wrap;
}
.padh10{
    padding-left: 10px;
    padding-right: 10px;
}
.btn{
    padding: 8px 20px;
   
    color: rgb(18, 18, 18);
    border: 1px solid rgb(89, 89, 89);
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    box-shadow:  0px 2px  rgba(157, 156, 156, 0.32);

    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    max-width: 200px;
    opacity: .8;

}
.btn:hover{
   
    opacity: 1;
    transform: scale(.98, .98);
  
  
    
}
.btn > div{
    pointer-events: none;
}

.btn-success{
    border-color: green;
    color: green
}
.btn img{
    height: 15px;
    margin: 5px;
    position: relative;
    top: 2px;
}
#client_name_view_clients{
    font-size: 1.5em;
    padding: 20px;
}
.accountActions{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 20px 0;
}
.accountActions > div{
    margin: 10px;
}

.btn_filled{
    padding: 8px 20px;
   
    color: rgb(255, 255, 255);
    
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    box-shadow:  0 0 3px rgba(204, 204, 204, 0.309);

    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    max-width: 300px;
    opacity: .8;
    background-color: rgb(2, 148, 220);

}
.btn_filled:hover{
   
    opacity: 1;
    
    
    
}
.btn_filled > div{
    pointer-events: none;
}

.btn_aqua{
    border-color: rgb(2, 148, 220);
    color: rgb(2, 148, 220)
}
.form_page_main{
    padding: 20px 2%;
}
.form_main{
    display: flex;
    flex-flow: row wrap;
    margin: 10px 0 ;
  

}
.form_centered{
    justify-content: center;
}
.form_section{
    display: flex;
    flex-direction: column;
    margin: 20px;

}

.form_section_title{
    padding: 10px 0;
    font-weight: 500;
    color: rgb(0, 105, 150);
    border-bottom: 1px solid #ccc;
}
.form_title{
    padding: 10px 0;
    font-weight: 500;
    color: rgb(0, 105, 150);
    border-bottom: 1px solid #ccc;
    width: 250px;
  
}

.form_section_main{
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.form_section_main > div{
    margin: 20px 10px; 

}
.center_form_section{
    align-items: center;
}

.imageMain{
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
}
.imageMain > div{
    margin: 20px 10px; 


}
.submit_form{
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  
}
.submit_form2{
    height: 150px;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    
  
}

.profileImageContainer{
    height: 100px;
    width: 100px;
    background-color: rgb(230, 230, 230);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 2px 2px #ccc;
    overflow: hidden;
}
.profileImageContainer > img{
    height: 100px;
    width: 100px;
}

.profileImageContainerFlat{
    height: 100px;
    width: 100px;
    background-color: rgb(230, 230, 230);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 2px #ccc;
    overflow: hidden;
}
.profileImageContainerFlat > img{
    height: 100%;
}

.clientIdImageContainer{
    height: 150px;
    width: 250px;
    background-color: rgba(230, 230, 230, 0.544);
    display: flex;
    align-items: center;
    justify-content: center;
 
    box-shadow: 0 2px 2px #ccc;
    overflow: hidden;
    margin: 20px;
}
.clientIdImage{
    
    height: 150px;
    width: 100%;
}
.IdImagePlaceholder{
    
    width: 30%;
}

.profileImageName{
    margin-top: 30px;
    padding: 10px;
    text-align: center;
    border-top: 1px solid #ccc;
    color: rgb(0, 145, 255);
}
.hidden_file_input{
    display: none;
}
.splitPage{
    display: flex;
    flex-flow: row wrap;
}
.user_main{
    
    height: 100%;
}
.height100{
    height: 100%
}
#user_main_nav, .pageTopNav{
    display: flex;
    flex-flow: row wrap;
    padding: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

}
#user_main_nav > div, .pageTopNav > div{
    padding: 10px;
}
.list_page_main{
    display: flex;
    flex-flow: row wrap;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}
.listItem{

    margin: 10px 0;
    
    color: rgb(2, 120, 204)
}
.saleTotal{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 3px dotted #ccc;
    padding: 50px 20px;
    margin-top: 50px;
    font-size: 1.8em;
    color: rgb(0, 121, 202)
}
.disabled{
    pointer-events: none;
    opacity: .6;
}
.flex1{
    flex:1;
}
.padv10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.padv20{
    padding-top: 20px;
    padding-bottom: 20px;
}
.pad5{
    padding: 5px;
}
.pad10{
    padding: 10px;
}
.pad20{
    padding: 20px;
}
.serialNumber{
    padding: 5px 10px;

}
.authenticator_avatar{
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    border-right: 1px solid rgb(67, 67, 67);

}
.authenticator_avatar img{
    height: 25px;
    width: 25px;
}
.floatingAction{
    position: fixed;
    bottom: 50px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.uni_procced_btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}
.user_info_main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.AccountName{
    padding: 40px 0 10px;
}
.account_main_info{
    padding: 10px 0;
}
.user_account_main_info{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}
.user_account_main_info > div{
    padding: 0 20px;
    min-width: 200px;
    box-sizing: border-box;
}


.my_account_main_info{
    display: flex;
    flex-flow: row wrap;
    
    align-items: flex-start;
}
.my_account_main_info > div{
    padding: 0 20px;
    min-width: 250px;
    box-sizing: border-box;
}

.shop_info_main, .clientInfoMain{

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.userInfoMain{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    
   
}  
.btn_red{
    border-color: red;
    color: red;
}
.PlaceholderShopImage{
    width: 100%;
    height: 250px;
    overflow: hidden;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shopImage{
    width: 100%;
    height: 250px;
    overflow: hidden;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shopImage > img{
    width: 300px;
}
.PlaceholderShopImage > img{
    height: 90%
}

.shop_main_info_container, .clientAccountMain{
 

    margin: 40px 0;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: hidden;
   
}

.subTable{
    width: 100%;

}

.shopName{
    font-size: 1.7em;
    background-color: rgb(237, 237, 237);
    padding: 10px 20px;
    border-radius: 50px;
    width: 250px;
    box-sizing: border-box;
    text-align: center;
    margin: 20px 0;
    
}
.clientIDAvatar{
    height: 100px;
    width: 150px;
    overflow: hidden;
}
.clientIDAvatar > img{
    height: 100%;
    width: 100%;
}
.client_container{

    margin: 20px;
    padding: 10px ;
    border-radius: 3px;
    background-color: rgb(249, 249, 249);
    box-shadow: 0 2px 3px rgb(240, 240, 240);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
}
.client_container div, .client_container img{
    pointer-events: none;
}
.clientsListMain{
    padding: 20px;
    display: flex;
    flex-flow: row wrap;
}
.flex_align_center{
    padding-bottom: 10px;
}
.ProfileImage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.searchBarSmall{
    display: flex;
    align-items: center;
    width: 30%;
    padding: 0px 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 3px rgba(204, 204, 204, 0.646);
}
.searchBarSmall > div:nth-child(1){
    flex:1;
}
.searchBarSmall input[type='text']{
    border: none;
    background-color: none;
    
    width: 100%;
}
.searchBarSmall input[type='text']:focus{
    border: none;
    outline: none;
}
.searchInFilter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 3px rgba(204, 204, 204, 0.646);
}
.selectedSearchItem{
    margin: 20px 0;
    width: 250px;
    background-color: rgb(0, 179, 255);
    padding: 20px;
    box-sizing: border-box;
    color: white;
}
.modifyStoreImage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.ModStoreImage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.centeredTabs{
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.tabs{
    margin: 20px 0 40px;
    padding: 10px;
   
    width: 100%;  
    overflow-x: auto;  
    box-sizing: border-box;
}
.tabBody{
   width: 1500px;
   
}
.tabBody_small{
    width: 500px;   
}
.tabBody_medium{
    width: 1000px;   
}
.autoTabBody{
    width:auto
}
.spaceOutTabs{
    height: 100px;
}
.underline{
    border-bottom: 1px solid #ccc;
}
.table_row{
    cursor: pointer;
    user-select: none;
}
.viewRecordActions > div{
    margin: 5px;
}
.doughnut_container{
    height: 200px;
    width: 200px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.doughnutCount{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1.2em;

}
.dashlist{
    height: 250px;
    overflow-y:auto;

}
#alert-dialog-title{
    font-weight: 300;
    padding-bottom: 5px;
    margin-bottom: 30px;
}
.underlined_title{
    display: flex;
    align-items: center;
    font-size: 1.3em;
    font-weight: 400;
    padding: 10px 0;
    color: black;
}
.underlined_title > div{
    border-bottom: 1px solid #ccc;
    padding: 0 20px 10px 0;
}
.filterSearchRes{
    position: relative;
}
.searchRBody{
    padding: 20px;
    width: 250px;
    max-height: 150px;

    position: absolute;
    background-color: white;
    z-index: 200;
    overflow-y: auto;
}

.sResult{
    padding: 10px;
    cursor: pointer;
    background-color: rgba(204, 204, 204, 0.072);
    border-radius: 4px;
    margin: 5px 0;
    font-weight: 300;
}
.generalSearchResult{
    padding: 20px 10px;
    cursor: pointer;
    background-color: rgba(204, 204, 204, 0.072);
    border-radius: 4px;
    margin: 5px 0;
}

.filterInput{
    margin: 20px 0;
}
.dateTimeFInput{
    margin: 20px 0;
}

.clientAccounting, .clientDebts{
    padding: 20px;
    margin: 20px;
}
.clientDebts{
    flex: 1;
}

.debtsMain{
    overflow: auto;
    max-height: 300px;
}
.debt{
    margin: 10px;

}
.stove_debt{
    padding: 10px;
    color: white;

    border-radius: 10px;
    margin: 10px 0;
}
.stove_debt > div{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    
}
.stove_debt > div > div:last-child{
    padding-left: 10px;
    font-weight: 500;

    
}
.debtHead{
    padding-bottom: 5px;
    border-bottom: 1px solid white;
}
#debtWarning{
    padding: 10px 20px;
    background-color: rgb(255, 79, 79);
    color: white;
    margin: 20px 10px;
    width: 60%;
    
}
.account_main_info_section{
    padding: 20px;
}

.link_text{
    color: rgb(62, 113, 255)
}
.idIcon > img{
    height: 20px;
}
.clientDebt{

    padding: 20px;
    margin: 20px;
}
.warning_text{
    color: red;
    font-weight: 400;
}
.warning_text_lite{
    color: red;
    font-weight: 300;
}
.inline_heading{
    display: inline-block;
    border-bottom: 1px solid #ccc;
    padding: 2px 10px 2px 0;
    margin: 10px 0;
}
#acountMain{
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
}
#login_page_main{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgb(232, 232, 232);

}
#login_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;

}
#loginBtn{
    background-color: rgb(25, 113, 255);
    color: white;

    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
}
#login_welcome{
    font-size: 2em;
    
    
}
#login_welcome_main{
    color: rgb(79, 79, 79);

    font-weight: 300;
    margin-bottom: 50px;
}
#login_welcome_main > div{

}
#loginSideBar{
    background-color: rgba(197, 197, 197, 0.38);
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login_input, .width350{
    width: 350px;
}
#login_footer{
    font-weight: 300;
    font-size: .8em;
    margin-top: 100px;
    color: rgb(43, 43, 43);
}
#sideBarMain{
    color: rgb(32, 32, 32);
    width: 50%;
    text-align: center;
}
#sidebarMainText{
    font-size: 1.5em;
    font-weight: 300;
    text-transform: capitalize;
    color: rgb(0, 37, 94);
}
#sideBarlogoContainer{
    padding-bottom: 50px;
}
#sideBarlogoContainer img{
    height: 100px;
}
#update_profile_image_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#update_password_main{
    padding: 20px;

}
.purchasedStove{
    margin: 10px 0;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 20px;
}
.purchasedStove > div:nth-child(2){
    padding-left: 20px;
}
.small{
    font-size: .9em;
}
.smallBold{
    font-size: .9;
    font-weight: 600;
}
.clientInfoContainer{
    margin: 20px;
    padding: 20px;
    width: 300px;

}
#purchased_stoves{
    max-height: 300px;
    overflow-y: auto;
}


.noselect{
    pointer-events: none;
}
.tableSearchBar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}
#settings_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.settings_section{
    padding: 50px 5%;
    margin: 20px;
    width: 60%;
    background-color: white;
}
#error_page{
    position: absolute;
    top: 0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}
.error_type{
    font-size: 3em;
    font-weight: 500;
}
#not_allowed_text{
    font-size: 1.5em;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.843)
}
#error_page_logo > img{
    height: 150px
}
#error_page_logo{
    padding: 20px;
}
.minWidth200, .tableDateTime{
    min-width: 200px;
}
.minWidth100{
    min-width: 100px;
}
.transferPelletMain{
  min-height: 300px;  
}

.table_page_main{

    width: 100%;
    display: flex;
    justify-content: center;
}
.pagetableContainer{
    width: 80%;
}
.responsiveBtn > div:nth-child(1){
    display: none;
}
.centerPage{
    padding: 0 5%;
}
.BtnListcontainer{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    width: 100%;
}
.BtnListcontainer > div{
    margin: 0 10px
}
.ImageAndName{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.td_min100{
    min-width: 120px;
}
.td_min200{
    min-width: 200px;
}
.td_min300{
    min-width: 300px;
}
.td_min50{
    min-width: 50px;
}
.optionPicker{
    display: flex;
    align-items: center;
}
.picker_option{
    width: 150px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 3px #ccc;
    margin: 10px;
    font-weight: 500;
    cursor: pointer;
}
.activePicker{
    color: white;
    background-color: orangered;
}
@media screen and (max-width: 1536px){
    
}
@media screen and (max-width:1200px){
    #debtWarning{
        width: 95%;
    }
}
@media screen and (max-width:900px){
    #loginSideBar{
        display: none;
    }
    .login_input, .width350{
        width: 300px;
    }
    .container{
       
        min-width: 250px;
        
    }

    .account_main_info_section{
        padding: 10px;
    }
    .clientInfoContainer{
        margin: 20px 0;
        padding: 20px 0;
        width: 100%;
    
    }
    .clientDebts{
        padding: 20px 0;
        margin: 20px 0;
    }
    .list_page_main{
     
        justify-content: center;
    }
    .clientsListMain{
        justify-content: center;
    }
    .searchBarSmall{
        flex:1;
    }
}
@media screen and (max-width:600px){
    .login_input, .width350{
        width: 250px;
    }
    #user_main_nav, .pageTopNav {

        justify-content: space-between;
      
        padding: 20px 10px;
        
    }
    .doughnut_container{
        box-sizing: border-box;
        width: 100%;
        
    }
    .user_account_main_info > div{
        width: 100%;
    }
    .page2PageContainer{
        width: 100%;
    }
    .responsiveBtn{
        padding: 8px 10px;
    }
    .responsiveBtn > div:nth-child(1){
        display: block;
    }
    .responsiveBtn > div:nth-child(2){
        display: none;
    }
    .clientsListMain{
        padding: 20px 10px;
    }
    .client_container{

        width: 100%;
    }
    .centerPage{
        padding: 0;
    }
}


