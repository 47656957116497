@font-face {
    font-family: NotoSansMono;
    src: url('../resources/fonts/NotoSansMono-VariableFont_wdth\,wght.ttf');
}
@font-face {
    font-family: OpenSans;
    src: url('../resources/fonts/OpenSans-VariableFont_wdth,wght.ttf');
}
#main{
    font-family: OpenSans, sans-serif;
    font-size: .9em;
    position: absolute;
    top: 0;
    left:0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    color: rgb(66, 66, 66);
    overflow: hidden;
    
}
#main_render_space{
    padding: 10px 20px;
    overflow-y: hidden;
    width: 100%;
    height: 100%;

    box-sizing: border-box;
}

#navBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
}
.navBarContainer{

    
    z-index: 500;
    width: 100%;
}

.pushSnack{
    margin-top: 60px;
}
#userNavPanel{
  display: flex;
  align-items: center;  
  cursor: pointer;
}
#userNavPanel div{
    pointer-events: none;
}
#userImageNavBar{
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    margin: 5px;
}
#userImageNavBar> img{
    height: 25px;
    width: 25px;
}
.profileSettings{
    width: 250px;
}
#pageOutlet{
    overflow-y: hidden;
    height: calc(100% - 50px);
}
